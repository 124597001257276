.taskFootage{
    background-color: whitesmoke;
    width: 100%;
    height: 50px;
    position: relative;
    position: absolute;
    bottom: 0;
    right: 0;
    text-align: right;
    padding-bottom: 20px;
    hr {
        opacity: 0.3;
    }
    div {
        display: flex;
        justify-content: space-between;
        padding: 0 15px;
        button {
            border: none;
            background: none;
            font-size: 1.1rem;
            cursor: pointer;
            color: #3b82f7;
            margin-top: 10px;
            padding: 10px 15px;
            font-weight: light;
            border: none;
            border-radius: 10px;
            background-color: #fff;
        }
    }
   
}
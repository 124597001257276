.searchBar {
    display: grid;
    grid-template-columns: 30px calc(100% - 40px);
    background-color: #fff;
    border-radius: 8px;
    width: calc(100% - 10px);
    margin-bottom: 10px;
    padding: 5px;
    
    .searchIcon {
        font-size: 1.8rem;
    }
    .search {
        background: none;
        font-size: 1.1rem;
        border-radius: 8px;
        padding: 5px;
        width: 100%;
    }
}